<template>
  <b-card>
    <!-- form -->
    <validation-observer ref="createProduct">
      <b-form
        class="mt-2"
        @submit.prevent="handleSubmit"
        @keydown.enter="handleSubmit"
      >
        <b-row>
          <b-col cols="12">
            <h4 class="mb-2">
              {{ $t('Añadir Producto') }}
            </h4>
          </b-col>
          <b-col sm="6">
            <validation-provider
              #default="{ errors }"
              :name="$t('Nombre')"
              rules="required"
            >
              <b-form-group
                :label="$t('Nombre')"
                label-for="product-name"
              >
                <b-form-input
                  v-model="name"
                  name="name"
                  :placeholder="$t('Nombre')"
                />
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <b-col sm="6">
              <validation-provider
                      #default="{ errors }"
                      :name="$t('Categoría')"
                      rules="required"
              >
                  <b-form-group
                          :label="$t('Categoría')"
                          label-for="product-category"
                  >
                    <SelectCategories v-model="category" />
                  </b-form-group>
                  <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
          </b-col>
         <!-- <b-col sm="6">
            <validation-provider
              #default="{ errors }"
              :name="$t('Revisión obligatoria')"
            >
              <b-form-group
                :label="$t('Revisión obligatoria')"
                label-for="product-revision-obligatoria"
              >
                <b-form-checkbox
                  id="revision-obligatoria"
                  v-model="revisionObligatoria"
                  name="check-button-revision-obligatoria"
                  switch
                  class="mt-1"
                  inline
                />
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <b-col sm="6">
            <validation-provider
              #default="{ errors }"
              :name="$t('Revisión periódica')"
            >
              <b-form-group
                :label="$t('Revisión periódica')"
                label-for="product-revision-periodica"
              >
                <b-form-checkbox
                  id="revision-periodica"
                  v-model="revisionPeriodica"
                  :disabled="revisionPeriodicaDisabled"
                  name="check-button-revision-periodica"
                  switch
                  class="mt-1"
                  inline
                />
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <b-col sm="6" v-if="revisionPeriodica">
            <validation-provider
              #default="{ errors }"
              :name="$t('Frecuencia revisiones')"
              :rules="revisionPeriodicaRequired"
            >
              <b-form-group
                :label="$t('Frecuencia revisiones')"
                label-for="product-subgrupo"
              >
                <v-select
                  v-model="frecuencia"
                  :filterable="true"
                  :searchable="true"
                  :options="selectRevisiones"
                  :placeholder="$t('Frecuencia revisiones')"
                />
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>-->
          <b-col sm="12">
            <validation-provider
              #default="{ errors }"
              :name="$t('Descripcion')"
            >
              <b-form-group
                :label="$t('Descripcion')"
                label-for="product-description"
              >
                <quill-editor
                  v-model="description"
                />
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <b-col sm="6">
            <b-form-group
              :label="$t('Imagen')"
              label-for="product-image"
            >
              <ImageDropzone
                ref="image"
                :files="imagen"
                max-files="1"
              />
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group
              :label="$t('Documentos')"
              label-for="documents"
            >
              <ImageDropzone
                ref="documents"
                :files="documents"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12 text-right">
            <b-button
              type="submit"
              variant="primary"
              class="mt-2 mr-1"
            >
              {{ $t('Guardar') }}
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import {
  BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BCard, BFormCheckbox,
} from 'bootstrap-vue'
import { quillEditor } from 'vue-quill-editor'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
import Ripple from 'vue-ripple-directive'
import flatPickr from 'vue-flatpickr-component'
import ImageDropzone from '@/components/elements/ImageDropzone/ImageDropzone.vue'
import vSelect from 'vue-select'
import SelectCategories from '@/components/selectCategories/SelectCategories.vue'
import SelectProducts from "@/components/selectProducts/SelectProducts.vue";

export default {
  components: {
      SelectProducts,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    ValidationProvider,
    ValidationObserver,
    flatPickr,
    quillEditor,
    vSelect,
    ImageDropzone,
    BFormCheckbox,
    SelectCategories
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,
      email,
      documents: [],
      imagen: [],
      productPrepare: false,
      category: '',
      name: '',
      description: '',
      revisionObligatoria: false,
      revisionPeriodica: false,
      revisionPeriodicaDisabled: false,
      revisionPeriodicaRequired: '',
      frecuencia: false,
      selectRevisiones: [
        { value: '3', label: this.$t('trimestral') },
        { value: '6', label: this.$t('semestral') },
        { value: '12', label: this.$t('anual') },
      ],
    }
  },
  watch: {
    revisionObligatoria() {
      if (this.revisionObligatoria) {
        this.revisionPeriodica = true
        this.revisionPeriodicaDisabled = true
      } else {
        this.revisionPeriodicaDisabled = false
      }
    },
    revisionPeriodica() {
      if (this.revisionPeriodica) {
        this.revisionPeriodicaRequired = 'required'
      } else {
        this.revisionPeriodicaRequired = ''
      }
    },
  },
  computed: {
    ...mapGetters({
      localeDatePicker: 'languages/getLocaleDatePicker',
      currentClient: 'clients/getCurrentClient',
      currentCompany: 'companies/getCurrentCompany',
      role: 'auth/getRole',
      user: 'auth/getUser',
    }),
  },
  methods: {
    ...mapActions({
      create: 'products/create',

    }),
    handleSubmit() {
      this.$refs.createProduct.validate().then(success => {
        if (success) {
          const formData = this.createFormData()
          if (formData) {
            this.create({ product: formData })
          }
        }
      })
    },
    createFormData() {
      const data = this.$refs.image.getFormData('imagen')

      this.$refs.documents.getFormData('documents', data)

      data.append('name', this.name)
      if (!this.revisionObligatoria && !this.revisionPeriodica) {
        data.append('type_review_id', 5)
      } else if (this.revisionObligatoria) {
        if (this.frecuencia.value == 12) {
          data.append('type_review_id', 1)
        } else if (this.frecuencia.value == 6) {
          data.append('type_review_id', 3)
        } else if (this.frecuencia.value == 3) {
          data.append('type_review_id', 6)
        }
      } else if (this.revisionPeriodica) {
        if (this.frecuencia.value == 12) {
          data.append('type_review_id', 2)
        } else if (this.frecuencia.value == 6) {
          data.append('type_review_id', 4)
        } else if (this.frecuencia.value == 3) {
          data.append('type_review_id', 7)
        }
      }
      data.append('description', this.description)
      data.append('client_id', this.currentClient.id)
      data.append('category_id', this.category.id)

      return data
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
